/* -*- JS -*- */
/* 
// ------------------------------------------------------------------------
//
// This code is property of BioSilico Limited and is protected by
// copyright law and international treaties. Unauthorized
// reproduction or distribution of this file, or any portion of
// it may result in severe civil and criminal penalties, and will
// be prosecuted to the maximum extent possible under the law.
//
// = LIBRARY
//   HomepageJS
//
// = FILENAME
//   LoginComponent.css
//
// = AUTHOR
//   Who       When        Why/What
//   K. Plewa   09.11.2022  Development
//
// ------------------------------------------------------------------------ 
*/
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.signIn_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signIn_main {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 650px;
  min-width: 350px;
  background-color: #fff;
  padding: 20px;
  justify-content: space-around;
  height: var(--myHeight);
  min-height: 475px;
  position: relative;
  border-radius: 10px;
  border: 2px solid #f2b280;
}

.signin_microsoft {
  background-color: #fff;
  color: #008ad7;
  font-family: sans-serif !important;
  border-radius: 4px;
  text-align: center;
  justify-content: center;
  font-size: 32px;
  font-weight: 700;
  position: absolute;
  right: 2%;
  top: 32.5%;
  width: 215px;
}

.signin_classLink {
  background-color: #fff;
  color: #008ad7;
  border: 1px solid #008ad7;
  font-family: sans-serif !important;
  border-radius: 4px;
  text-align: center;
  justify-content: center;
  height: 45px;
  font-size: 28px;
  font-weight: 700;
  position: absolute;
  right: 2%;
  top: 52.5%;
  width: 215px;
}

.signin_clever {
  font-family: sans-serif !important;
  border-radius: 4px;
  text-align: center;
  font-size: 28px;
  height: 50px;
  font-weight: 700;
  position: absolute;
  right: 2%;
  top: 37.5%;
  width: 215px;
}

.overlayGray {
  background-color: rgba(180, 180, 180, 0.8);
  opacity: 0.5; /* reduce the opacity of the element to create the overlay effect */
  position: absolute; /* set the position to absolute so the overlay covers the entire element */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: not-allowed;
  z-index: 100; /* set the z-index to a higher value than the element you want to overlay, to ensure it appears on top */
}
.signin_classLink::content,
.signin_clever::content {
  background-color: rgb(112, 112, 112, 0.2);
}

span:hover {
  color: rgb(112, 112, 112);
}

.signIn_main button:hover {
  filter: brightness(0.7);
}

.GDPR_Policy {
  position: absolute;
  bottom: 1%;
  right: 1%;
}

a:visited {
  text-decoration: none;
  color: blue;
}

[data-amplify-authenticator] [data-amplify-container] {
  --amplify-components-authenticator-container-width-max: 23rem;
}

.amazon {
  place-self: flex-start;
  margin-bottom: 3%;
}

h3 {
  position: absolute;
  left: 33%;
  top: 2%;
}

.amazon {
  margin-top: 3%;
}

.otherProvider {
  display: none;
  position: relative;
}
.underConstruction h2 {
  position: relative;
  text-align: center;
  color: rgb(80, 80, 80);
}

@media only screen and (max-width: 675px) {
  .main_container {
    width: 100%;
    margin-top: 0px;
  }
  .signIn_main {
    max-width: 410px;
    max-height: 750px;
  }

  .GDPR_Policy {
    margin-left: 20px;
    margin-right: 20px;
  }

  .GDPR_Policy {
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
  }

  h3 {
    left: 15%;
    top: 1%;
  }

  .otherProvider {
    display: block;
    position: relative;
    left: 15%;
  }
  .otherProvider button {
    background-color: #f2b280;
    border-radius: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .otherProvider button:focus {
    background-color: #f2b280;
  }
  .signin_classLink,
  .signin_clever {
    left: 15%;
    display: none;
    position: relative;
  }
}

[data-amplify-authenticator] [data-amplify-container] {
  --amplify-components-authenticator-container-width-max: 23rem;
}

[data-amplify-authenticator] {
  place-self: flex-start;
}

.amazon {
  margin-bottom: 3%;
  margin-top: 3%;
}

/* -*- JS -*- */
/* 
// ------------------------------------------------------------------------
//
// This code is property of BioSilico Limited and is protected by
// copyright law and international treaties. Unauthorized
// reproduction or distribution of this file, or any portion of
// it may result in severe civil and criminal penalties, and will
// be prosecuted to the maximum extent possible under the law.
//
// = LIBRARY
//   HomepageJS
//
// = FILENAME
//   App.css
//
// = AUTHOR
//   Who       When        Why/What
//   K. Plewa   09.11.2022  Development
//
// ------------------------------------------------------------------------ 
*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: calibri !important;
}

body {
  overflow: auto !important;
  display: flex;
  position: inherit;
}

.App {
  background: #fff;
  height: 100vh;
  overflow: auto !important;
  min-height: 100vh;
  max-height: 200vh;
  min-width: 100vw;
}
